import { Environment } from '../app/interfaces';

export const environment: Environment = {
  production: false,
  authServerUrl: 'https://fresh-auth-service-311805066495.europe-west2.run.app',
  authEndpoints: {
    login: 'fp-auth-login-dev',
    forgotpass: 'fp-auth-forgotpass-dev',
    resetpass: 'fp-auth-resetpass-dev',
    resetvalidate: 'fp-auth-validatereset-dev',
    server: 'fp-auth-server-dev'
  },
  selectServer: 'select',
  selectServerGroups: [
    { name: 'global', production: true },
    { name: 'global', production: false },
    { name: 'testing', production: false }
  ]
};
