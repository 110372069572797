import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { LoginOptions, Server } from '../interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public formGroup = this.getFormGroup();
  public busy$ = this.authService.busy$;
  public loginError$ = new BehaviorSubject<string>(null);
  public resetError$ = new BehaviorSubject<string>(null);
  // 'login' | 'forgot' | 'forgot-success' | 'mfa-totp'
  public readonly viewState$ = new BehaviorSubject<string>('login');

  public showFeatureBase$ = this.authService.featureBase$;
  public mfaChallenge$ = new BehaviorSubject<any>(null);
  public mfaRequired$ = new BehaviorSubject<boolean>(false);

  public servers$ = new BehaviorSubject<Server[]>([]);

  public showServerSelection = environment.selectServer;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    // Caters for bad urls that have /# in them.
    // NOTE: Particularly important for sendgrid link tracking.
    // Sendgrid links will have a different window.location.hash to the window.location.href (won't match at all).
    // So MUST use the location.hash to get the query params.
    if (window.location.hash) {
      const url = window.location.origin + window.location.pathname;
      const queryParamIndex = window.location.hash.indexOf('?');
      const newUrl = url + window.location.hash.substring(queryParamIndex);
      window.location.href = newUrl;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['reset']) {
        this.router.navigate(['reset'], { queryParams: params });
        return;
      }
      if (params['email']) {
        this.formGroup.get('username').patchValue(params['email'], { emitEvent: false });
      }
      if (params['forgot']) {
        this.viewState$.next('forgot');
      }
    });

    if (environment.selectServer == 'select' || environment.selectServer == 'hidden') {
      this.authService.listServersForServerGroups(environment.selectServerGroups).subscribe((servers) => {
        this.servers$.next(servers.sort((a, b) => a.name.localeCompare(b.name)));
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private getFormGroup() {
    const fg = this.formBuilder.group({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      serverSelection: new FormControl('', [])
    });
    this.subscriptions.push(
      this.authService.busy$.subscribe((busy) => {
        if (busy) {
          fg.disable();
        } else {
          fg.enable();
        }
      })
    );

    return fg;
  }

  onLogin() {
    if (!this.formGroup.valid) {
      return;
    }
    const options: LoginOptions = {
      skipMfa: false,
      serverNameOverride: null
    };
    if (this.formGroup.value.serverSelection) {
      options.serverNameOverride = this.formGroup.value.serverSelection;
    }
    this.attemptLogin(this.formGroup.value.username, this.formGroup.value.password, null, options);
  }

  onVerifyMfa(data: any) {
    this.attemptLogin(this.formGroup.value.username, this.formGroup.value.password, data, null);
  }

  public attemptLogin(username: string, password: string, mfa: any, options: LoginOptions) {
    // Ensure form is up to date with values in case this got called from a different source
    this.formGroup.patchValue({ username, password }, { emitEvent: false });
    this.loginError$.next(null);
    this.authService.login(username, password, mfa, options).subscribe((response) => {
      this.busy$.next(false);
      if (response.mfaChallenge) {
        this.mfaChallenge$.next(response.mfaChallenge);
        const mfaConfigExistsWithKeys =
          !!response.mfaChallenge.mfaConfig && Object.keys(response.mfaChallenge.mfaConfig).length > 0;
        this.mfaRequired$.next(!!response.mfaChallenge.mfaRequired || !mfaConfigExistsWithKeys);
        this.viewState$.next('mfa-' + response.mfaChallenge.mfaType);
        if (mfa) {
          // We attempted to login with MFA and it failed during setup
          // show a message
          this.loginError$.next('MFA setup failed. Please try again.');
        }
        return;
      }
      if (response.serviceAccountSessionToken) {
        // redirect to the service account page
        this.router.navigate(['/ops']);
        return;
      }

      // This is only to handle errors.  If we successfully logged in, then the service will redirect.
      if (response.error) {
        // Failsafe to clear any fpserver or Parse sessions if there's an error
        this.authService.clearLoginData();

        if (response.error) {
          this.loginError$.next(response.error);
        } else {
          this.loginError$.next(response.error);
        }
        return;
      }
    });
  }

  onShowForgotPassword() {
    this.viewState$.next('forgot');
  }

  onShowLogin() {
    this.viewState$.next('login');
    this.resetError$.next(null);
    this.loginError$.next(null);
  }

  onForgotPassword() {
    const controlEmail = this.formGroup.get('username');
    if (!controlEmail.valid) {
      return; // Dont need a notification as the input will be highlighted as problematic.
    }

    this.authService.forgotPassword(controlEmail.value).subscribe((response) => {
      if (response.success) {
        this.formGroup.patchValue({ password: '' }, { emitEvent: false });
        this.formGroup.controls.password.markAsPristine();
        this.viewState$.next('forgot-success');
      } else if (response.error && response.error?.status !== 500) {
        if (response.error?.message) {
          this.resetError$.next(response.error.message);
        } else {
          this.resetError$.next(response.error);
        }
        return;
      }
    });
  }

  onMfaSkip() {
    this.attemptLogin(this.formGroup.value.username, this.formGroup.value.password, null, { skipMfa: true });
  }
}
