<ng-container *ngIf="(viewState$ | async) === 'login'">
  <div class="title" *ngIf="!(showFeatureBase$ | async)">Log in to Fresh Projects</div>
  <div class="title" *ngIf="showFeatureBase$ | async">Log in and continue to FeatureBase</div>
  <div class="title-description">Enter your details below</div>

  <div class="formgroup needs-validation" [formGroup]="formGroup" novalidate>
    <!-- <div
      class="input-group"
      [ngClass]="{
        'is-invalid': formGroup.touched && formGroup.get('username').invalid
      }"
    > -->
    <div class="form-input-group">
      <label for="username" class="form-label">Email address</label>
      <input
        autocomplete="off"
        class="form-control"
        type="email"
        id="username"
        placeholder="user@company.com"
        formControlName="username"
        [ngClass]="{
          'is-invalid': formGroup.touched && formGroup.get('username').invalid
        }"
      />
      <!-- </div> -->
      <div class="invalid-feedback">Must be a valid email address</div>
    </div>

    <!-- <div
      class="input-group"
      [ngClass]="{
        'is-invalid': formGroup.touched && formGroup.get('password').invalid
      }"
    > -->
    <div class="forgot-wrapper">
      <label for="password" class="form-label">Password</label>
      <div class="form-label forgot" (click)="onShowForgotPassword()">Forgot your password?</div>
    </div>
    <div class="form-input-group">
      <input
        autocomplete="off"
        class="form-control"
        type="password"
        id="password"
        placeholder="Please enter your password"
        formControlName="password"
        (keyup.enter)="onLogin()"
        [ngClass]="{
          'is-invalid': formGroup.touched && formGroup.get('password').invalid
        }"
      />
      <div class="invalid-feedback">Cannot be empty</div>
    </div>

    <div class="form-input-group" *ngIf="showServerSelection && showServerSelection === 'select'">
      <label for="username" class="form-label">Server</label>
      <select class="form-control" formControlName="serverSelection">
        <option value="">Automatic</option>
        <option *ngFor="let server of servers$ | async" [value]="server.serverName">{{ server.name }}</option>
      </select>
    </div>

    <button class="btn btn-primary" id="login" type="button" [disabled]="!formGroup.valid" (click)="onLogin()">
      Log In
      <span class="spinner-border spinner-border-sm" *ngIf="(busy$ | async) === true"></span>
    </button>
  </div>

  <div class="is-invalid" *ngIf="loginError$ | async"></div>
  <div class="form-error invalid-feedback" *ngIf="loginError$ | async">{{ loginError$ | async }}</div>

  <!-- </div> -->

  <ng-container *ngTemplateOutlet="support"></ng-container>
</ng-container>

<!-- RESET Password Form -->
<ng-container *ngIf="(viewState$ | async) === 'forgot'">
  <div class="title">Forgot Password?</div>
  <div class="title-description">We'll help you reset it and get back on track.</div>

  <div class="formgroup needs-validation form-reset-password" [formGroup]="formGroup" novalidate>
    <div class="form-input-group">
      <label for="username" class="form-label">Email address</label>
      <input
        autocomplete="off"
        class="form-control"
        type="email"
        id="username"
        placeholder="user@company.com"
        formControlName="username"
        [ngClass]="{
          'is-invalid': formGroup.touched && formGroup.get('username').invalid
        }"
      />
      <div class="invalid-feedback">Must be a valid email address</div>
    </div>

    <button
      class="btn btn-primary"
      id="forgotPass"
      type="button"
      [disabled]="!formGroup.controls.username.valid"
      (click)="onForgotPassword()"
    >
      Reset password
      <span class="spinner-border spinner-border-sm" *ngIf="(busy$ | async) === true"></span>
    </button>

    <div class="form-error invalid-feedback" *ngIf="resetError$ | async">{{ resetError$ | async }}</div>
  </div>

  <div class="back-to-login">
    <div (click)="onShowLogin()">Back to login</div>
  </div>

  <ng-container *ngTemplateOutlet="support"></ng-container>
</ng-container>

<!-- RESET Password Form -->
<ng-container *ngIf="(viewState$ | async) === 'forgot-success'">
  <div class="title">Check your email</div>
  <div class="title-description">
    If the account exists, we’ll send password reset instructions to
    <span class="span-email">{{ formGroup.controls.username.value }}</span>
  </div>

  <button class="btn btn-primary" type="button" (click)="onShowLogin()">Back to login</button>

  <ng-container *ngTemplateOutlet="support"></ng-container>
</ng-container>

<!-- MFA - TOTP -->
<ng-container *ngIf="(viewState$ | async) === 'mfa-totp'">
  <app-mfa-totp
    [mfaChallenge]="mfaChallenge$ | async"
    [hasError]="!!(loginError$ | async)"
    [busy]="busy$ | async"
    (mfaData)="onVerifyMfa($event)"
    (showLogin)="onShowLogin()"
  >
    <div class="form-errors">
      <div class="form-error invalid-feedback" *ngIf="loginError$ | async">{{ loginError$ | async }}</div>
    </div>
  </app-mfa-totp>

  <ng-container *ngTemplateOutlet="support"></ng-container>

  <ng-container *ngTemplateOutlet="mfaskip"></ng-container>
</ng-container>

<ng-template #support>
  <div class="support-helper">
    Can't log in? Contact <a href="mailto:support@freshprojects.co.za">support&#64;freshprojects.cloud</a>
  </div>
</ng-template>

<ng-template #mfaskip>
  <div class="mfa-skip" *ngIf="!(mfaRequired$ | async)">
    You can choose to <span (click)="onMfaSkip()">skip 2FA verification</span> at this moment, we’ll remind you again on
    your next login.
  </div>
</ng-template>
