// Route guard to check that service account session exists in local storage

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_SERVICE_ACCOUNT_SESSION } from '../consts';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): boolean {
    if (localStorage.getItem(STORAGE_SERVICE_ACCOUNT_SESSION)) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
