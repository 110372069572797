<div class="show-mobile mobile-screen-warning" *ngIf="!(allowSmallScreens$ | async)">
  <div class="mobile-screen-warning-container">
    <div class="mobile-header">
      <div class="logo">
        <img src="assets/fp-logo-horizontal.svg" />
      </div>
    </div>
    <div class="mobile-image">
      <!-- <div class="mobile-image-bubble"></div> -->
      <img src="assets/fp-small-screen.png" />
    </div>
    <div class="mobile-title">Fresh Projects is not optimised for use on small screens</div>
    <div class="mobile-description">For an improved experience, use a bigger screen to access this page.</div>
  </div>
</div>
<div class="body-wrapper hide-mobile" [class.hide-mobile]="(allowSmallScreens$ | async) == false">
  <div class="dev-mode" *ngIf="(devAuthServer | async) || (devLoginServer | async)">
    <div class="title">Dev Mode</div>
    <div class="server">
      Auth server: {{ devAuthServer | async }}
      <button class="btn" type="button" (click)="onClearDevAuthServer()">
        <i class="far fa-lg fa-times-circle"></i>
      </button>
    </div>
    <div class="server">
      Login server:
      {{ devLoginServer | async }}
      <button class="btn" type="button" (click)="onClearDevLoginServer()">
        <i class="far fa-lg fa-times-circle"></i>
      </button>
    </div>
  </div>
  <!-- OTHER Routes -->
  <ng-container *ngIf="(mode$ | async) === 'other'">
    <div class="page-wrapper page-wrapper-other">
      <div class="page-header-with-logo">
        <div class="logo">
          <img src="assets/fp-logo-horizontal.svg" />
        </div>
      </div>
      <div class="page-content-other">
        <router-outlet></router-outlet>
      </div>
      <div class="page-footer">
        <div class="app-version">{{ appVer }}</div>
      </div>
    </div>
  </ng-container>

  <!-- MAIN LOGIN ROUTES -->
  <ng-container *ngIf="(mode$ | async) !== 'other'">
    <div class="page-sidebar">
      <div class="logo">
        <img src="assets/fp-logo-horizontal.svg" />
      </div>
      <div class="sidebar-background">
        <img src="assets/login-bg-image.png" />
      </div>

      <div class="sidebar-book-a-demo">
        <div class="book-title">Don't have an account yet?</div>
        <div class="book-description">Project management software for Architects and Engineers.</div>

        <a class="book-action" href="https://www.gofreshprojects.com/book-your-demo">Book a demo</a>
      </div>
    </div>

    <div class="page-wrapper" [class.logo-top]="(mode$ | async) === 'register'">
      <div class="page-content">
        <div [class.hidden]="(mode$ | async) !== 'login'">
          <app-login #appLogin></app-login>
        </div>
        <ng-container *ngIf="(mode$ | async) === 'resetpassword'">
          <app-reset-password
            (onShowLogin)="onSwitchToLogin()"
            (attemptLogin)="mode$.next('login'); appLogin.attemptLogin($event.username, $event.password, null, null)"
          ></app-reset-password>
        </ng-container>
        <ng-container *ngIf="(mode$ | async) === 'register'">
          <app-create-account></app-create-account>
          <div class="mt-5 text-center" ng-if="!signingup">
            <div class="register" (click)="onSwitchToLogin()"><u>Login</u> to an existing account</div>
          </div>
        </ng-container>
        <ng-container *ngIf="(mode$ | async) === 'migrating'">
          <app-migration></app-migration>
          <div class="forgot forgot-wrapper">
            <div (click)="onSwitchToLogin()">back to login</div>
          </div>
        </ng-container>

        <router-outlet></router-outlet>

        <div class="app-error" *ngIf="error$ | async">{{ error$ | async }}</div>
      </div>

      <div class="page-footer">
        <div class="app-version">{{ appVer }}</div>
      </div>
    </div>
  </ng-container>
</div>
