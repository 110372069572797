<!-- 
 - verify reset code: 
   - if valid, show new password input

- if invalid
  - show request new password
  - show error

- back button?
 -->

<ng-container *ngIf="codeValid$ | async">
  <div class="title">Reset your password</div>
  <div class="title-description">Enter the new password for your Fresh Project’s account.</div>

  <!-- Valid code, enter new password form -->
  <div class="formgroup needs-validation" [formGroup]="formGroup" novalidate>
    <div class="form-input-group">
      <label for="password" class="form-label">New Password</label>
      <input
        autocomplete="off"
        class="form-control"
        type="password"
        id="password"
        placeholder="Please enter your new password"
        formControlName="password"
        [ngClass]="{
          'is-invalid': formGroup.touched && formGroup.get('password').invalid
        }"
      />
      <div class="invalid-feedback">Minimum of 10 characters</div>
    </div>
    <button class="btn btn-primary" id="login" type="button" [disabled]="!formGroup.valid" (click)="onResetPassword()">
      Save new password
      <span class="spinner-border spinner-border-sm" *ngIf="(busy$ | async) === true"></span>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showResetSent$ | async">
  <div class="title">Check your email</div>
  <div class="title-description">
    If the account exists, we’ll send password reset instructions to
    <span class="span-email">{{ formGroup.controls.username.value }}</span>
  </div>

  <button class="btn btn-primary" type="button" (click)="onReturnToLogin()">Back to login</button>
</ng-container>

<ng-container *ngIf="error$ | async">
  <div class="title title-error">Password reset link expired</div>
  <div class="title-description">
    Click the button below to receive a new password reset link to
    <span class="span-email">{{ formGroup.value.username }}</span>
  </div>
  <!-- Invalid code for some reason.  Show error. -->
  <button type="button" class="btn btn-primary" (click)="onSendPasswordReset()">Resend password reset email</button>
</ng-container>

<!-- loader -->
<div class="row mt-5 text-center starterText" *ngIf="busy$ | async">
  <div class="col text-center starterText">
    <span class="spinner-border spinner-border-sm"></span>
  </div>
</div>

<div class="support-helper">
  Can't log in? Contact <a href="mailto:support@freshprojects.co.za">support&#64;freshprojects.cloud</a>
</div>
