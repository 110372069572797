import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { renderFreshProjectsSvgToConsole } from './console.svg';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ResetPasswordComponent } from './forgot-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { TotpComponent } from './mfa/totp/totp.component';
import { MigrationComponent } from './migration/migration.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CreateAccountComponent,
    ResetPasswordComponent,
    MigrationComponent,
    TotpComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router) {
    // log query params
    const queryParams = this.router.parseUrl(window.location.search).queryParams;

    const subscription = router.events.subscribe((event: NavigationEnd) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      // preserve the query params when hash based routing initializes
      router.navigate([], {
        queryParams,
        queryParamsHandling: 'merge'
      });
      setTimeout(() => {
        subscription.unsubscribe();
      });
    });

    renderFreshProjectsSvgToConsole();
  }
}
