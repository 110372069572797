export interface Environment {
  production: boolean;
  /**
   * If set to true, don't redirect after login and don't save fpserver details.
   */
  dummyLogin?: boolean;
  /**
   * If defined, use this uri for connecting to auth server.
   * If not defined, look for auth server under subpath https://<hostname>/config/auth-server
   */
  authServerUrl?: string;
  /**
   * If defined, uses this path for fetching config.
   * Defaults to https://<hostname>/config/auth-server
   */
  configUrl?: string;
  /**
   * What url to use when running on localhost.  Defaults to configUrl.
   */
  configUrlLocalhost?: string;

  /**
   * If defined, use this uri for connecting to cloud auth server.
   */
  cloudAuthUrl?: string;
  /**
   * Set default settings
   */
  serverConfig?: ServerConfig;
  /**
   *
   */
  authEndpoints?: AuthEndpoints;

  /**
   * Whether to allow user to pick which server to connect to.
   * 'none' / null / undefined - don't show server selection
   * 'select' - show server selection
   * 'hidden' - selection is hidden by default, but can be optionally shown
   */
  selectServer?: ServerSelection;
  selectServerGroups?: ServerSelectionGroup[];
}

export type ServerSelection = 'none' | 'select' | 'hidden';

export interface ServerSelectionGroup {
  name: string;
  production: boolean;
}
export interface AuthEndpoints {
  login: string;
  forgotpass: string;
  resetpass: string;
  resetvalidate: string;
  server: string;
}
export interface ServerConfig {
  authUrl: string;
  apiUrlOverride?: string;
  webUrlOverride?: string;
  appId?: string;
  jsKey?: string;
  serverName?: string;
  authCloudUrl?: string;
  appVer?: string;
  authDirect?: boolean;
}

export interface FPServer {
  id: string;
  name: string;
  secondaryUrl: string;
  apiUrl: string;
  externalApiUrl: string;
  appId: string;
  jsKey: string;
  flag: string;
  isDefault?: boolean;
  appVer: string;
  migrating?: boolean;
  parsePath?: string;
  // url to redirect to for login
  liveServer: string;
  // current url
  webUrl: string;
}

export interface LoginResponse {
  token: string;
  error?: any;
}

export interface AccountCreationRequest {
  firstName: string;
  lastName: string;
  emailAddress: string;
  locale: string;
  password: string;
  companyName: string;
  server: string;
  refer?: string;
  refCode?: string;
  poc?: boolean;
}

export interface AuthServerRequest {
  action: 'getUserServer';
  email: string;
}

export interface Server {
  _id: string;
  serverName: string;
  apiUrl: string;
  externalApiUrl: string;
  appId: string;
  jsKey: string;
  name: string;
  /**
   * Path to add to apiUrl for Parse endpoints.
   * Example: /parse or /1.  Blank for no path.
   */
  parsePath: string;
}

export interface AuthResponse {
  server: Server;
  sessionToken: string;
  appVer: string;
  migrating: boolean;
  mfaChallenge?: MfaChallenge;
}

export interface MfaChallenge {
  mfaType: string;
  /**
   * This should only be set when the user is setting up MFA for the first time.
   */
  mfaConfig?: any;
}

export interface LoginOptions {
  skipMfa: boolean;
  serverNameOverride?: string;
}

export interface RequestFindServer {
  email?: string;
  serverName?: string;
  secondaryUrl?: string;
  list?: boolean;
  listProduction?: boolean;
  listGroup?: string;
}
